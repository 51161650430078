import React from 'react'
import { List } from 'semantic-ui-react'
import { ScrapeItem } from '../../types/ScrapeItem'
import ArticlePreview from './ArticlePreview'
import { ArticleAttrs } from '../../types/Article'

interface Props {
  items?: (ScrapeItem | ArticleAttrs)[]
}

const ArticlePreviewList = ({ items }: Props) => {
  return <List divided relaxed>
    {items?.map((item, index) => <ArticlePreview
      key={index}
      item={item}
    />)}
  </List>
}

export default ArticlePreviewList