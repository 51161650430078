import React from 'react'
import { Icon, Label, LabelProps } from 'semantic-ui-react'
import { ScraperType } from '../../types/Scraper'

type Props = {
  type: ScraperType
  labelProps?: LabelProps
}

const articleTypes = {
  youtube: {
    name: "Youtube", icon: "youtube", iconColor: "#FF2944"
  },
  pubmed: {
    name: "PubMed", icon: "atom", iconColor: "#3f6695"
  },
  blog: {
    name: "Blog", icon: "blog", "iconColor": "#12AFF2"
  },
  rss: {
    name: "Blog", icon: "blog", iconColor: "#12AFF2"
  },
  email: {
    name: "Email", icon: "robot", iconColor: "#6bc8fc"
  },
  ctgov: {
    name: "ClinicalTrials.gov", icon: "atom", iconColor: "#1f5492"
  }
}

const ScraperTypeLabel = ({ type, labelProps }: Props) => {
  // const tags = useSelector((state: RootState) => state.tags)
  // const clusters = useSelector((state: RootState) => state.clusters)
  const articleType = type && articleTypes ? articleTypes[type] : undefined
  return (
    <Label {...labelProps}>
      {type === 'blog' && <Icon name='globe' />}
      {type === 'rss' && <Icon name='rss' />}
      {type === 'youtube' && <Icon name='youtube' />}
      {type === 'email' && <Icon name='mail' />}
      {type === 'pubmed' && <Icon name='book' />}
      {type === 'ctgov' && <Icon name='book' />}
      {articleType?.name}
    </Label>
  )
}

export default ScraperTypeLabel
