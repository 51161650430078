import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Button, Divider, Form, Header, Message, Modal } from 'semantic-ui-react'
import { ScraperAddAttrs, ScraperAttrs, ScraperUpdateAttrs } from '../../types/Scraper'
import { DisorderListData, GET_DISORDERS } from '../disorder/DisorderList'
import { GET_SCRAPER, ScraperFormData, ScraperFormVars } from './GetScraperQuery'
import ScraperFormConfig from './ScraperFormConfig'
import { GET_SCRAPERS, ScraperListData } from './ScraperList'

const ADD_SCRAPER = gql`
  mutation Mutation($type: String!, $enabled: Boolean, $config: JSON, $name: String!, $disorderIds: [String]) {
    addScraper(type: $type, enabled: $enabled, config: $config, name: $name, disorderIds: $disorderIds) {
      id
      type
      enabled
      config
      playlist {
        name
        disorders {
          id
        }
      }
    }
  }
`

const UPDATE_SCRAPER = gql`
  mutation Mutation($id: ID!, $type: String! $enabled: Boolean, $config: JSON, $name: String!, $disorderIds: [String]) {
    updateScraper(id: $id, type: $type, enabled: $enabled, config: $config, name: $name, disorderIds: $disorderIds) {
      id
      type
      enabled
      config
      playlist {
        name
        disorders {
          id
        }
      }
    }
  }
`

const defaultValues = { disorderIds: [] }

type Props = {
  scraperId: string
  action: 'edit' | 'new'
}

const ScraperForm = ({ scraperId, action }: Props) => {
  // const { loading, error, data, refetch } = useQuery<ScraperFormData, ScraperFormVars>(
  console.log(scraperId)
  const { loading, error, data } = useQuery<ScraperFormData, ScraperFormVars>(GET_SCRAPER, {
    variables: { id: scraperId },
  })
  const { scraper } = data || {}
  const [updateScraper] = useMutation<ScraperAttrs, ScraperUpdateAttrs>(UPDATE_SCRAPER)
  const [createScraper] = useMutation<ScraperAttrs, ScraperAddAttrs>(ADD_SCRAPER, {
    update(cache, { data: scraper }) {
      const data2 = cache.readQuery<ScraperListData>({ query: GET_SCRAPERS });
      const { scrapers } = data2 || {}
      if (!scrapers || !scraper) return
      cache.writeQuery({
        query: GET_SCRAPERS,
        data: { scrapers: scrapers.concat([scraper]) },
      });
    }
  })
  const { data: data2 } = useQuery<DisorderListData>(GET_DISORDERS)
  const { disorders } = data2 || {}

  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    errors,
    control,
    setError,
    clearError,
    reset,
    formState: { dirty }
  } = useForm<ScraperAddAttrs>({ defaultValues })

  const type = watch('type')

  const handleClose = (force = false) => {
    if (!force && dirty && !window.confirm('Cancel edits?')) return
    reset(defaultValues)
    history.push({
      pathname: '/scrapers',
      search: history.location.search,
    })
  }

  useEffect(() => {
    register({ name: "config" })
  }, [register])

  useEffect(() => {
    if (action === 'edit' && scraper) {
      reset({
        ...defaultValues,
        type: scraper.type,
        config: scraper.config || {},
        enabled: !!scraper.enabled,
        name: scraper.playlist.name,
        disorderIds: scraper.playlist.disorders?.map(d => d.id) || []
      })
    } else {
      reset(defaultValues)
    }
  }, [reset, scraper, setValue, action])

  const handleSubmit2 = handleSubmit(async (data) => {
    setLoading(true)
    if (action === 'edit' && scraperId) {
      // edit
      await updateScraper({
        variables: { id: scraperId, type: data.type, enabled: data.enabled, config: data.config, name: data.name, disorderIds: data.disorderIds }
      })
    } else {
      await createScraper({
        variables: { type: data.type, enabled: data.enabled, config: data.config, name: data.name, disorderIds: data.disorderIds }
      })
      // new
    }
    setLoading(false)
    handleClose(true)
  })

  useEffect(() => {
    clearError('config')
  }, [clearError, type])

  return <>
    <Modal
      open={action === 'new' || action === 'edit'}
      onClose={() => handleClose()}>
      <Modal.Header>{action === 'new' ? 'New' : 'Edit'} Scraper</Modal.Header>
      <Modal.Content>
        {error ? <Message>Error: {error.message}</Message> : undefined}
        <Form loading={isLoading || loading} onSubmit={handleSubmit2}>
          <Form.Field required error={!!errors.name}>
            <label>Name</label>
            <input
              type='text'
              name='name'
              ref={register({ required: true })}
            />
          </Form.Field>
          <Form.Field>
            <Controller
              as={<Form.Select
                label='Disorders'
                options={disorders?.map(disorder => ({
                  key: disorder.id,
                  value: disorder.id,
                  text: disorder.name,
                })) || []}

              />}
              multiple
              control={control}
              name='disorderIds'
              onChange={([_, { value }]) => value}
            />
          </Form.Field>
          <Form.Field>
            <Controller
              as={<Form.Select
                label='Type'
                required
                clearable
                options={[
                  { key: 'blog', value: 'blog', text: 'Blog' },
                  { key: 'rss', value: 'rss', text: 'RSS' },
                  { key: 'youtube', value: 'youtube', text: 'YouTube' },
                  { key: 'pubmed', value: 'pubmed', text: 'Pubmed' },
                  { key: 'email', value: 'email', text: 'Email' },
                  { key: 'ctgov', value: 'ctgov', text: 'clinicaltrials.gov' },
                ]}
              />}
              rules={{ required: true }}
              control={control}
              name='type'
              onChange={([_, { value }]) => value}
              error={!!errors.type}
            />
          </Form.Field>
          <Form.Field error={errors.enabled}>
            <Controller
              as={<Form.Checkbox
                label='Enable'
              />}
              control={control}
              name='enabled'
              onChange={([_, { checked }]) => !!checked}
              error={error}
            />
          </Form.Field>
          <Divider />
          <Header>Configuration</Header>
          <ScraperFormConfig
            type={type}
            initialConfig={scraper?.config}
            setConfig={(value?: Object) => setValue('config', value)}
            clearError={() => clearError('config')}
            setError={(type, message) => setError('config', type, message)}
            error={errors.config}
          />
          <Button primary type='submit'>Submit</Button>
        </Form>
      </Modal.Content>
    </Modal>
  </>
}

export default ScraperForm