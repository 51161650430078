import { ApolloError } from '@apollo/client'
import React from 'react'
import { Dimmer, Image, Loader, Message, Segment } from 'semantic-ui-react'

type Props = {
  error?: ApolloError
  loading: boolean
}

const ApolloLoading: React.FunctionComponent<Props> = ({ loading, error, children }) => {
  return !loading && !error
    ? <>{children}</>
    : loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader active>Loading</Loader>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Dimmer>
      </Segment>
      : <Message negative>
        Something went wrong fetching results: {error?.message}
      </Message>
}

export default ApolloLoading