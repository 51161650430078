import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { DisorderAttrs } from '../../types/Disorder'
import ApolloLoading from '../ApolloLoading'
import DisorderItem from './DisorderItem'

export interface DisorderListData {
  disorders?: DisorderAttrs[]
}

export const GET_DISORDERS = gql`
  query Query {
    disorders {
      id
      name
    }
  }
`

const DisorderList = () => {
  const { loading, error, data } = useQuery<DisorderListData>(GET_DISORDERS)
  const { disorders } = data || {}
  return (
    <ApolloLoading loading={loading} error={error}>
      <Table
        // definition
        compact
        renderBodyRow={(disorder: DisorderAttrs) => <DisorderItem
          key={disorder.id}
          disorder={disorder}
        />}
        headerRow={['Name', '']}
        tableData={disorders || []}
        celled
      />
    </ApolloLoading>
  )
}

export default DisorderList