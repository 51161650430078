import update from 'immutability-helper';
import { Schema, ValidationError, Validator } from 'jsonschema';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Button, Form, Message, TextAreaProps } from 'semantic-ui-react';
import { ScraperAttrs, ScraperType, SCRAPER_TYPES } from '../../types/Scraper';

type Props = {
  type: ScraperType
  initialConfig: ScraperAttrs['config']
  setConfig: (config: ScraperAttrs['config']) => void
  clearError: () => void
  setError: (type: string, message: string) => void
  error?: FieldError
}

const blogCssSchema: Schema = {
  id: '/blogCss',
  type: 'object',
  properties: {
    find: { type: 'string' },
    get: { type: 'string' },
    property: { type: 'string' },
    regex: { type: 'array' },
    prepend: { type: 'string' },
  },
  required: ['get'],
}

const emailFilterSchema: Schema = {
  id: '/emailFilter',
  type: 'object',
  properties: {
    host: { type: 'string' },
    path: { type: 'string' },
    param: { type: 'string' },
  },
  required: ['host', 'path'],
}

const typeSchema: { [key in ScraperType]: Schema } = {
  blog: {
    id: '/blog',
    type: 'object',
    properties: {
      url: { type: 'string' },
      selector: { type: 'string' },
    },
    required: ['url', 'selector'],
  },
  rss: {
    id: '/rss',
    type: 'object',
    properties: {
      url: { type: 'string' },
      id: { type: 'string' },
      description: { type: 'string' },
      postFetchOg: {
        type: 'array',
        items: { type: 'string' }, // 'description' | 'title' | 'image'
      },
    },
    required: ['url'],
  },
  ctgov: {
    id: '/ctgov',
    type: 'object',
    properties: {
      url: { type: 'string' },
    },
    required: ['url'],
  },
  pubmed: {
    id: '/pubmed',
    type: 'object',
    properties: {
      rssGuid: { type: 'string' },
    },
    required: ['rssGuid'],
  },
  email: {
    id: '/email',
    type: 'object',
    properties: {
      mailgunEmail: { type: 'string' },
      filter: { '$ref': '/emailFilter' },
    },
    required: ['mailgunEmail', 'filter'],
  },
  youtube: {
    id: '/youtube',
    type: 'object',
    properties: {
      channelId: { type: 'string' },
    },
    required: ['channelId'],
  },
}

const schema: Schema = {
  "id": "/config",
  "type": "object",
  "properties": {
    id: { '$ref': '/blogCss' },
    date: { '$ref': '/blogCss' },
    description: { '$ref': '/blogCss' },
    image: { '$ref': '/blogCss' },
    title: { '$ref': '/blogCss' },
    url: { '$ref': '/blogCss' },
    blog: { '$ref': '/blog' },
    rss: { '$ref': '/rss' },
    ctgov: { '$ref': '/ctgov' },
    pubmed: { '$ref': '/pubmed' },
    email: { '$ref': '/email' },
    youtube: { '$ref': '/youtube' },
  },
  required: [],
}

const requiredForType: {
  [key in ScraperType]: string[]
} = {
  // blog: ['id', 'date', 'description', 'image', 'title', 'url'],
  blog: ['id', 'date', 'title', 'url'],
  rss: [],
  ctgov: [],
  pubmed: [],
  email: [],
  youtube: [],
}

const schemaValidator = new Validator()
schemaValidator.addSchema(blogCssSchema)
schemaValidator.addSchema(emailFilterSchema)
SCRAPER_TYPES.forEach(t => schemaValidator.addSchema(typeSchema[t]))

const ScraperFormConfig = ({ type, initialConfig: config, setConfig, clearError, setError, error }: Props) => {
  const [schemaErrors, setSchemaErrors] = useState<ValidationError[]>()
  const [value, setValue] = useState('')

  const handleConfigFormat = useCallback(() => {
    try {
      const configPretty = JSON.stringify(JSON.parse(value), undefined, 2)
      setValue(configPretty)
    } catch (error) {
    }
  }, [value])

  useEffect(() => {
    try {
      // try to parse JSON
      const configParsed = JSON.parse(value)
      setConfig(configParsed)
      const required = [type, ...requiredForType[type]]
      const schema2 = update(schema, { required: { $push: required } })
      const result = schemaValidator.validate(configParsed, schema2)
      setSchemaErrors(result.errors)
      if (result.errors?.length) {
        setError('format', `The schema is incorrect for type ${type}`)
      }
    } catch (error) {
      setError('format', 'The JSON is not formatted correctly (2)')
    }
  }, [value, setError, type, setConfig])

  const handleConfigChange = useCallback((
    event: React.FormEvent<HTMLTextAreaElement>,
    data: TextAreaProps
  ) => {
    const value = data.value?.toString() || ''
    setValue(value)
    clearError()
  }, [clearError])

  useEffect(() => {
    const value = JSON.stringify(config, undefined, 2)
    setValue(value)
  }, [config])

  return <>
    <Form.Field>
      <Form.TextArea
        className='fixed-font'
        label='Config'
        name='config'
        value={value}
        disabled={!type}
        onChange={handleConfigChange}
        error={error ? { content: error.message } : undefined}
      />
      <Button size='mini' type='button' onClick={handleConfigFormat}>Format</Button>
    </Form.Field>
    {schemaErrors?.length ? <Message
      // warning
      header={`There is a formatting error for ${type}`}
      list={schemaErrors?.map(error => error.stack)}
    /> : undefined}
  </>
}

export default ScraperFormConfig