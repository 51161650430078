import moment from 'moment'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Table, Button, Label } from 'semantic-ui-react'
import { PlaylistAttrs } from '../../types/Playlist'

type Props = {
  playlist: PlaylistAttrs
}

const PlaylistItem = ({ playlist }: Props) => {
  const { playlistId } = useParams<{ playlistId?: string }>()
  const history = useHistory()

  const handleDetail = () => {
    history.push({
      pathname: `/playlists/detail/${playlist.id}`,
      search: history.location.search,
    })
  }

  const active = playlistId === playlist.id

  return <>
    <Table.Row active={active}>
      <Table.Cell singleLine>
        {playlist.name}
        &nbsp;
        {playlist.articleCount
          ? <Label circular>{playlist.articleCount}</Label>
          : undefined
        }
      </Table.Cell>
      <Table.Cell singleLine>
        {playlist.createdAt ? moment(playlist.createdAt).fromNow() : undefined}
      </Table.Cell>
      <Table.Cell singleLine>
        {playlist.updatedAt ? moment(playlist.updatedAt).fromNow() : undefined}
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          onClick={handleDetail}
          content='View'
        />
      </Table.Cell>
    </Table.Row>
  </>
}

export default PlaylistItem