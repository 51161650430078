import React from 'react'
import { List, Image, Label } from 'semantic-ui-react'
import { ScrapeItem } from '../../types/ScrapeItem'
import moment from 'moment'
import { ArticleAttrs } from '../../types/Article'

interface Props {
  item: ScrapeItem | ArticleAttrs
}

const ArticlePreview = ({ item }: Props) => {
  return <List.Item>
    <List.Content>
      {item.image?.url ? <Image avatar src={item.image?.url} floated='left' /> : undefined}
      <List.Header as='a' href={item.url} target='_blank'>
        {item.title || '?'}
        &nbsp;<Label
          size='tiny'
          content={moment(item.publishedAt).calendar()}
        />
      </List.Header>
      <List.Description
        content={item.description || '?'}
      />
    </List.Content>
  </List.Item >
}

export default ArticlePreview