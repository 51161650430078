import React from 'react'
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom"
import { Button, Container, Grid, Menu } from 'semantic-ui-react'
import './App.css'
import Navigation from './component/Navigation'
import ScraperDetail from './component/scraper/ScraperDetail'
import ScraperForm from './component/scraper/ScraperForm'
import ScraperList from './component/scraper/ScraperList'
import PlaylistList from './component/playlist/PlaylistList'
import PlaylistDetail from './component/playlist/PlaylistDetail'
import DisorderForm from './component/disorder/DisorderForm'
import DisorderList from './component/disorder/DisorderList'


function App() {
  const location = useLocation()
  const history = useHistory()

  return <>
    <Navigation />
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Menu pointing secondary>
              {['scrapers', 'playlists', 'disorders'].map(table => (
                <Menu.Item
                  as={Link}
                  key={table}
                  name={table}
                  active={location.pathname.startsWith('/' + table)}
                  to={table}
                // onClick={() => setActiveTable(table)}
                />
              ))}
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Switch>
              <Route path="/scrapers">
                <Button as={Link} to={`/scrapers/new${history.location.search}`}>New</Button>
                <Route path='/scrapers/:action/:scraperId?'
                  render={({ match: { params: { action, scraperId } } }) =>
                    action === 'edit' || action === 'new'
                      ? <ScraperForm
                        scraperId={scraperId}
                        action={action}
                      />
                      : action === 'detail'
                        ? <ScraperDetail
                          key={scraperId}
                          scraperId={scraperId}
                        />
                        : undefined
                  }
                />
                <ScraperList />
              </Route>
              <Route path="/disorders">
                <Button as={Link} to={`/disorders/new${history.location.search}`}>New</Button>
                <Route path='/disorders/:action/:disorderId?'
                  render={({ match: { params: { action, disorderId } } }) =>
                    action === 'edit' || action === 'new'
                      ? <DisorderForm
                        disorderId={disorderId}
                        action={action}
                      />
                        : undefined
                  }
                />
                <DisorderList />
              </Route>
              <Route path="/playlists">
                <Route path='/playlists/:action/:playlistId?'
                  render={({ match: { params: { action, playlistId } } }) =>
                    action === 'detail'
                      ? <PlaylistDetail
                        key={playlistId}
                        playlistId={playlistId}
                      />
                      : undefined
                  }
                />
                <PlaylistList />
              </Route>
            </Switch>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </>
}

export default App
