import { ScraperAttrs } from "../../types/Scraper";
import { gql } from "@apollo/client";

export interface ScraperFormData {
  scraper?: ScraperAttrs
}

export interface ScraperFormVars {
  id: string
}

export const GET_SCRAPER = gql`
  query Query($id: ID!) {
    scraper(id: $id) {
      id
      type
      enabled
      config
      playlist {
        name
        disorders {
          id
        }
      }
    }
  }
`
