import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Label, Modal } from 'semantic-ui-react'
import { PlaylistAttrs } from '../../types/Playlist'
import ApolloLoading from '../ApolloLoading'
import ArticlePreviewList from '../article/ArticlePreviewList'

interface PlaylistDetailData {
  playlist?: PlaylistAttrs
}

interface PlaylistDetailVars {
  id: string
}

const GET_PLAYLIST = gql`
  query Query($id: ID!) {
    playlist(id: $id) {
      id
      name
      articles {
        title
        description
        url
        image
        publishedAt
      }
    }
  }
`

type Props = {
  playlistId: string
}

const PlaylistDetail = ({ playlistId }: Props) => {
  const { loading, error, data } = useQuery<PlaylistDetailData, PlaylistDetailVars>(
    GET_PLAYLIST,
    { variables: { id: playlistId } }
  )
  const { playlist } = data || {}

  const history = useHistory()

  const handleClose = () => {
    history.push({
      pathname: '/playlists',
      search: history.location.search,
    })
  }

  return (
    <Modal open onClose={handleClose}>
      <ApolloLoading loading={loading} error={error}>
        <Modal.Header>
          {playlist?.name}
          &nbsp;<Label content={playlist?.id} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ArticlePreviewList items={playlist?.articles} />
          </Modal.Description>
        </Modal.Content>
      </ApolloLoading>
    </Modal >
  )
}

export default PlaylistDetail