import { gql, useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Button, Form, Message, Modal } from 'semantic-ui-react'
import { DisorderAddAttrs, DisorderAttrs, DisorderUpdateAttrs } from '../../types/Disorder'
import { GET_DISORDERS } from './DisorderList'

interface DisorderFormData {
  disorder?: DisorderAttrs
}

interface DisorderFormVars {
  id: string
}

const GET_DISORDER = gql`
  query Query($id: ID!) {
    disorder(id: $id) {
      id
      name
    }
  }
`

const ADD_DISORDER = gql`
  mutation Mutation($name: String!) {
    addDisorder(name: $name) {
      id
      name
    }
  }
`

const UPDATE_DISORDER = gql`
  mutation Mutation($id: ID!, $name: String!) {
    updateDisorder(id: $id, name: $name) {
      id
      name
    }
  }
`

const defaultValues = {}

type Props = {
  disorderId?: string
  action: 'edit' | 'new'
}

const DisorderForm = ({ disorderId, action }: Props) => {
  const [
    getDisorder,
    { loading, error, data, refetch }
  ] = useLazyQuery<DisorderFormData, DisorderFormVars>(GET_DISORDER)
  const { disorder } = data || {}
  const [updateDisorder] = useMutation<DisorderAttrs, DisorderUpdateAttrs>(UPDATE_DISORDER)
  const [createDisorder] = useMutation<DisorderAttrs, DisorderAddAttrs>(ADD_DISORDER, {
    refetchQueries: [{
      query: GET_DISORDERS
    }]
  })

  useEffect(() => {
    if (!disorderId) return
    getDisorder({ variables: { id: disorderId } })
  }, [disorderId, refetch, getDisorder])


  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { dirty }
  } = useForm<DisorderAddAttrs>({ defaultValues })

  const handleClose = (force = false) => {
    if (!force && dirty && !window.confirm('Cancel edits?')) return
    reset(defaultValues)
    history.push({
      pathname: '/disorders',
      search: history.location.search,
    })
  }

  useEffect(() => {
    if (action === 'edit' && disorder) {
      reset({
        ...defaultValues,
        name: disorder.name
      })
    } else {
      reset(defaultValues)
    }
  }, [reset, action, disorder])

  const handleSubmit2 = handleSubmit(async (data) => {
    setLoading(true)
    if (action === 'edit' && disorderId) {
      // edit
      await updateDisorder({
        variables: { id: disorderId, name: data.name }
      })
    } else {
      // new
      await createDisorder({
        variables: { name: data.name }
      })
    }
    setLoading(false)
    handleClose(true)
  })

  return <>
    <Modal
      open={action === 'new' || action === 'edit'}
      onClose={() => handleClose()}>
      <Modal.Header>{action === 'new' ? 'New' : 'Edit'} Disorder</Modal.Header>
      <Modal.Content>
        {error ? <Message>Error: {error.message}</Message> : undefined}
        <Form loading={isLoading || loading} onSubmit={handleSubmit2}>
          <Form.Field required error={!!errors.name}>
            <label>Name</label>
            <input
              type='text'
              name='name'
              ref={register({ required: true })}
            />
          </Form.Field>
          <Button primary type='submit'>Submit</Button>
        </Form>
      </Modal.Content>
    </Modal>
  </>
}

export default DisorderForm