import moment from 'moment'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Table, Button, Label } from 'semantic-ui-react'
import { ScraperAttrs } from '../../types/Scraper'
import ScraperTypeLabel from './ScraperTypeLabel'

type Props = {
  scraper: ScraperAttrs
}

const ScraperItem = ({ scraper }: Props) => {
  const { scraperId } = useParams<{ scraperId?: string }>()
  const history = useHistory()

  const handleDetail = () => {
    history.push({
      pathname: `/scrapers/detail/${scraper.id}`,
      search: history.location.search,
    })
  }

  const handleEdit = () => {
    history.push({
      pathname: `/scrapers/edit/${scraper.id}`,
      search: history.location.search,
    })
  }

  const error = !!scraper.errorAt
  const success = !error && !!scraper.scrapedAt
  const active = scraperId === scraper.id

  return <>
    <Table.Row
      active={active}
      negative={error}
      positive={success}>
      <Table.Cell singleLine>
        {scraper.playlist.name}
        &nbsp;
        {scraper.articleCount
          ? <Label circular>{scraper.articleCount}</Label>
          : undefined
        }
      </Table.Cell>
      <Table.Cell singleLine>
        <ScraperTypeLabel type={scraper.type} />
        {!scraper.enabled
          ? < Label color='red'>Disabled</Label>
          : undefined}
      </Table.Cell>
      <Table.Cell singleLine>
        {scraper.playlist.disorders?.map(disorder => disorder.name).join(', ')}
      </Table.Cell>
      <Table.Cell singleLine>
        {scraper.scrapedAt ? moment(scraper.scrapedAt).fromNow() : undefined}
      </Table.Cell>
      <Table.Cell singleLine>
        {scraper.errorAt ? moment(scraper.errorAt).fromNow() : undefined}
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          onClick={handleDetail}
          content='View'
        />
        <Button
          onClick={handleEdit}
          content='Edit'
        />
      </Table.Cell>
    </Table.Row>
  </>
}

export default ScraperItem