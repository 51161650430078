import React, { useCallback } from 'react'
import { Menu } from 'semantic-ui-react'

const Navigation = () => {
  const handleSignIn = useCallback(async () => {
    const apiKey = window.prompt('Api Key', window.localStorage.getItem('apiKey') || '')
    if (!apiKey) return
    window.localStorage.setItem('apiKey', apiKey)
  }, [])

  return (
    <Menu inverted fixed='top'>
      <Menu.Item header color='black'>
        RarePulse Admin
      </Menu.Item>
      <Menu.Item
        name='signIn'
        onClick={handleSignIn}>
        Login
      </Menu.Item>
    </Menu>
  )
}

export default Navigation