import { PlaylistAttrs } from "./Playlist";

export type ScraperType = 'blog' | 'youtube' | 'rss' | 'ctgov' | 'pubmed' | 'email'
export const SCRAPER_TYPES: ScraperType[] = ['blog', 'rss', 'youtube', 'pubmed', 'email', 'ctgov']

export interface ScraperAddAttrs {
  name: string // playlist item
  disorderIds?: string[]
  type: ScraperType
  enabled?: boolean
  config?: object
}

export interface ScraperUpdateAttrs extends ScraperAddAttrs {
  id: string
}

export interface ScraperAttrs extends Omit<ScraperUpdateAttrs, 'name' | 'disorderIds'> {
  playlist: PlaylistAttrs
  articleCount: number
  errorAt?: string
  scrapedAt?: string
  createdAt: string
  updatedAt: string
}


