import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { PlaylistAttrs } from '../../types/Playlist'
import ApolloLoading from '../ApolloLoading'
import PlaylistItem from './PlaylistItem'

interface PlaylistListData {
  playlists?: PlaylistAttrs[]
}

const GET_PLAYLISTS = gql`
  query Query {
    playlists {
      id
      name
      articleCount
      createdAt
      updatedAt
    }
  }
`

const PlaylistList = () => {
  const { loading, error, data } = useQuery<PlaylistListData>(GET_PLAYLISTS)
  const { playlists } = data || {}
  return (
    <ApolloLoading loading={loading} error={error}>
      <Table
        // definition
        compact
        renderBodyRow={(playlist: PlaylistAttrs) => <PlaylistItem
          key={playlist.id}
          playlist={playlist}
        />}
        headerRow={['Name', 'Created', 'Updated', '']}
        tableData={playlists || []}
        celled
      />
    </ApolloLoading>
  )
}

export default PlaylistList