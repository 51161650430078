import { gql, useQuery } from '@apollo/client'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { useHistory } from 'react-router-dom'
import { Button, Header, Label, Message, Modal, Divider } from 'semantic-ui-react'
import { ScraperAttrs } from '../../types/Scraper'
import ApolloLoading from '../ApolloLoading'
import ScraperTypeLabel from './ScraperTypeLabel'
import { ScrapeItem } from '../../types/ScrapeItem'
import ArticlePreviewList from '../article/ArticlePreviewList'
import { SERVER_URL } from '../../constants'

interface ScraperDetailData {
  scraper?: ScraperAttrs
}

interface ScraperDetailVars {
  id?: string
}

const GET_SCRAPER = gql`
  query Query($id: ID!) {
    scraper(id: $id) {
      id
      type
      config
      articleCount
      errorAt
      scrapedAt
      playlist {
        name
      }
    }
  }
`

type Props = {
  scraperId: string
}

const ScraperDetail = ({ scraperId }: Props) => {
  const { loading, error, data, refetch } = useQuery<ScraperDetailData, ScraperDetailVars>(
    GET_SCRAPER,
    { variables: { id: scraperId } }
  )
  const { scraper } = data || {}

  const history = useHistory()
  const [isTesting, setIsTesting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [testItems, setTestItems] = useState<ScrapeItem[]>()
  const [isTestSuccess, setIsTestSuccess] = useState(false)


  useEffect(() => {
    setTestItems(undefined)
    setErrorMessage(undefined)
    setIsTestSuccess(false)
    setIsTesting(false)
  }, [setTestItems, scraperId])

  const handleClose = () => {
    history.push({
      pathname: '/scrapers',
      search: history.location.search,
    })
  }

  const handleEdit = () => {
    history.push({
      pathname: `/scrapers/edit/${scraper?.id}`,
      search: history.location.search,
    })
  }

  const handleTest = async () => {
    await handleSingle(true)
  }

  const handleSingle = async (dryrun?: boolean) => {
    if (!dryrun && !window.confirm('Are you sure you want to run this?')) return
    setTestItems(undefined)
    setIsTesting(true)
    setErrorMessage(undefined)
    try {
      const url = dryrun ? SERVER_URL + '/v1/scrapeTest' : SERVER_URL + '/v1/scrape'
      const apiKey = window.localStorage.getItem('apiKey')
      const results = await axios.post(
        url,
        { scraperId },
        {
          headers: { Authorization: `Bearer ${apiKey}` }
        }
      )
      setTestItems(results.data)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
    }
    await refetch()
    setIsTesting(false)
  }

  const handleTestSuccess = async (success = true) => {
    setIsTestSuccess(success)
  }

  return (
    <Modal open onClose={handleClose}>
      <ApolloLoading loading={loading} error={error}>
        <Modal.Header>
          {scraper?.playlist.name}
          &nbsp;<Label content={scraper?.id} />
          <Button
            floated='right'
            compact
            color='blue'
            onClick={handleEdit}
            icon='pencil'
            content='Edit'
          />
          {testItems ? <Button
            disabled={isTestSuccess}
            floated='right'
            compact
            color='green'
            onClick={() => handleTestSuccess()}
            content='Mark Test Success'
          /> : undefined}
          <Button
            floated='right'
            compact
            loading={isTesting}
            onClick={handleTest}
            content='Test Scraper'
          />
          <Button
            // disabled={!scraper.auto}
            floated='right'
            compact
            color='red'
            loading={isTesting}
            onClick={() => handleSingle()}
            content='Run Scraper'
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Label.Group>
              {scraper?.type && <ScraperTypeLabel
                type={scraper.type}
              />}
              {isTestSuccess
                ? <Label
                  active
                  onClick={() => handleTestSuccess(false)} color='green'
                  content='Test Success'
                /> : undefined}
            </Label.Group>
            <Header>Test/scrape results</Header>
            {errorMessage ? <Message negative>{errorMessage}</Message> : undefined}
            {testItems ? <>
              <Button
                content='Clear Results'
                icon='times'
                onClick={() => setTestItems(undefined)}
              />
              <ArticlePreviewList items={testItems} />
              <Divider />
              <ReactJson name='items' src={JSON.parse(JSON.stringify(testItems))} />
            </> : <p>Run the testing to see the results.</p>}
            <Header>Config</Header>
            {scraper?.config
              ? <ReactJson name='config' src={scraper.config} />
              : <Label color='red'>No Config</Label>
            }
          </Modal.Description>
        </Modal.Content>
      </ApolloLoading>
    </Modal >
  )
}

export default ScraperDetail