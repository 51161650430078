import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Table } from 'semantic-ui-react'
import { DisorderAttrs } from '../../types/Disorder'

type Props = {
  disorder: DisorderAttrs
}

const DisorderItem = ({ disorder }: Props) => {
  const { disorderId } = useParams<{ disorderId?: string }>()
  const history = useHistory()

  const handleEdit = () => {
    history.push({
      pathname: `/disorders/edit/${disorder.id}`,
      search: history.location.search,
    })
  }

  const active = disorderId === disorder.id

  return <>
    <Table.Row active={active}>
      <Table.Cell singleLine>
        {disorder.name}
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          onClick={handleEdit}
          content='Edit'
        />
      </Table.Cell>
    </Table.Row>
  </>
}

export default DisorderItem