import { gql, useQuery } from '@apollo/client'
import qs from 'query-string'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import ApolloLoading from '../ApolloLoading'
import { ScraperAttrs } from '../../types/Scraper'
import ScraperItem from './ScraperItem'

export interface ScraperListData {
  scrapers?: ScraperAttrs[]
}

export const GET_SCRAPERS = gql`
  query Query {
    scrapers {
      id
      type
      enabled
      errorAt
      scrapedAt
      articleCount
      playlist {
        name
        disorders {
          name
        }
      }
    }
  }
`

const ScraperList = () => {
  const { loading, error, data } = useQuery<ScraperListData>(GET_SCRAPERS)
  const location = useLocation()
  const search = qs.parse(location.search)
  const filterCluster = search.cluster?.toString()
  const { scrapers } = data || {}
  return (
    <ApolloLoading loading={loading} error={error}>
      <Table
        // definition
        compact
        renderBodyRow={(scraper: ScraperAttrs) => <ScraperItem
          key={scraper.id}
          scraper={scraper}
        />}
        headerRow={['Playlist', 'Type', 'Disorders', 'Fetched', 'Error', '']}
        tableData={scrapers || []}
        celled
        color={filterCluster ? 'blue' : undefined}
      />
    </ApolloLoading>
  )
}

export default ScraperList